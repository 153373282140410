import {
    Alert,
    alpha,
    Autocomplete,
    Avatar,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Chip,
    InputAdornment,
    Paper,
    Snackbar,
    Stack,
    TextField
} from "@mui/material";
import Typography from "@mui/material/Typography";
import React, {useEffect, useState} from "react";
import CustomDivider from "../../components/CustomDivider";
import {testEmail} from "../../services/utils";
import {AddCircle, AlternateEmail, Cancel, Edit, Restaurant, Save, Tour} from "@mui/icons-material";
import {useAuthStore} from "../../stores/AuthStore";
import {cloneDeep, find, isEmpty} from "lodash";
import dayjs from "dayjs";
import IconButton from "@mui/material/IconButton";
import {sticketTheme} from "../../theme/sticketTheme";
import relativeTime from "dayjs/plugin/relativeTime";
import useAxios from "axios-hooks";
import TourService from "../../services/tour.service";
import {useParams} from "react-router-dom";
import CustomTooltip from "../../components/CustomTooltip";
import CustomBackdrop from "../../components/CustomBackdrop";
import axios from "axios";
import {useTourStore} from "../../stores/TourStore";
import CustomChangeTourDateCatering from "./CustomChangeTourDateCateringPopper";
import AddTourDateDialog from "./AddTourDateDialog";

dayjs.locale("it");
dayjs.extend(relativeTime);

const SticketSettings = () => {
    const {tour_id} = useParams()
    const userRole = useAuthStore((state) => state.role)
    const tourStore = useTourStore((state) => state)

    const [{data: _tourDates, loading: loadingTourDates, error: errorTourDates}, fetchTourDates] = useAxios(
        TourService.tourDatesUrl(tour_id), {method: "GET", useCache: false, manual: false}
    )
    const [tourDates, setTourDates] = useState([])
    useEffect(() => {
        if (_tourDates) {
            setTourDates(_tourDates)
        }
    }, [_tourDates])

    const [{
        data: _tourCaterings,
        loading: loadingTourCaterings,
        error: errorTourCaterings
    }, fetchTourCaterings] = useAxios(
        TourService.tourCateringsUrl(tour_id), {method: "GET", useCache: false, manual: false}
    )
    const [tourCaterings, setTourCaterings] = useState([])

    useEffect(() => {
        if (_tourCaterings) {
            setTourCaterings(_tourCaterings)
        }
    }, [_tourCaterings])

    const [input, setInput] = useState('')
    const [error, setError] = useState(false)
    const [emails, setEmails] = useState([])

    useEffect(() => {
        if (tourStore?.emails_agency)
            setEmails(tourStore?.emails_agency || [])
    }, [tourStore?.emails_agency])

    function handleSaveEmails() {
        const _emails = cloneDeep(emails)
        if (!!input) {
            if (testEmail(input)) {
                _emails.push(input)
                setError(false)

                axios.put(TourService.tourDataUrl(tour_id), {
                    emails_agency: _emails
                }).then((res) => {
                    if (res?.data) {
                        useTourStore.setState((prev) => ({
                            ...prev,
                            ...res?.data
                        }))
                    }
                    setMessage({
                        ...message,
                        show: true,
                        text: "Aggiornamento andato a buon fine",
                        severity: "success"
                    })
                })
            } else {
                setError(true)
            }
        }

        axios.put(TourService.tourDataUrl(tour_id), {
            emails_agency: _emails
        }).then((res) => {
            if (res?.data) {
                useTourStore.setState((prev) => ({
                    ...prev,
                    ...res?.data
                }))
            }
            setMessage({
                ...message,
                show: true,
                text: "Aggiornamento andato a buon fine",
                severity: "success"
            })
        })
    }

    function handleChangeCatering(event, index) {
        let caterings = cloneDeep(tourCaterings)
        caterings[index] = {
            ...caterings[index],
            [event.target.id]: event.target.value
        }

        setTourCaterings(caterings)
    }

    function handleDelete(event, index) {
        const newCaterings = cloneDeep(tourCaterings)
        newCaterings.splice(index, 1)

        setTourCaterings(newCaterings)
    }

    const [message, setMessage] = useState({show: false, text: "", severity: "success"})
    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setMessage({...message, show: false});
    };

    return (
        <Stack>
            <CustomBackdrop open={loadingTourDates || loadingTourCaterings}/>
            <Snackbar open={message.show} autoHideDuration={6000} onClose={handleCloseAlert}>
                <Alert elevation={6} variant={"filled"} onClose={handleCloseAlert}
                       severity={message.severity}
                       sx={{width: '100%'}}>
                    {message.text}
                </Alert>
            </Snackbar>
            <Typography gutterBottom variant={'h6'} fontWeight={'bold'} sx={{mb: 2}}>Settings</Typography>
            <Typography fontWeight={'bold'}>E-mail produzione a cui inviare report automatico giornaliero</Typography>
            <CustomDivider/>
            <Paper component={Stack} p={2} mb={3}>
                <Autocomplete
                    id={'emails'}
                    name={'emails'}
                    required
                    freeSolo
                    multiple
                    //options={emails}
                    options={[]}
                    value={emails}
                    disableClearable
                    onChange={(event, newValue) => {
                        //console.log("newValue:", newValue)
                        setEmails(newValue)
                    }}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            if (testEmail(input)) {
                                setEmails([...emails, input])
                                setInput('')
                                setError(false)
                            } else {
                                event.defaultMuiPrevented = true;
                                if (input)
                                    setError(true)
                            }
                        }
                    }}
                    //onChange={(!field.precompiled && !disabled) ? formik.handleChange : null}
                    sx={{width: '100%'}}
                    renderInput={(params) =>
                        <TextField {...params}
                                   variant={'standard'}
                                   size={'small'}
                                   error={!!input && error}
                                   onChange={(event) => {
                                       setInput(event.target.value)
                                   }}
                                   label={"E-mail produzione"}
                                   helperText={'Inserisci le e-mail a cui vuoi inviare il recap giornaliero. Premi invio per inserirne un\'altra'}
                        />}
                />
                <Button variant={'submit-primary'}
                        endIcon={<Save/>}
                        disabled={isEmpty(emails) || error || (emails === tourStore.emails_agency)}
                        sx={{ml: 'auto'}}
                        onClick={handleSaveEmails}
                >
                    Salva lista e-mail
                </Button>
            </Paper>
            {/*<Paper component={Stack} p={2} mb={3}>
                <Typography fontWeight={'bold'}>CATERING: Invia e-mail "Recap pasti consegnati" del
                    giorno {dayjs().subtract(1, "day").format("DD/MM/YY")} al catering</Typography>
                <CustomDivider/>
                <TextField id={'email_catering'}
                           name={'email_catering'}
                           variant={'standard'}
                           size={'small'}
                           error={!!input && error}
                           onChange={(event) => {
                               setEmailCatering(event.target.value)
                           }}
                           label={"E-mail catering"}
                           helperText={'Inserisci le e-mail a cui vuoi inviare il recap. Premi invio per inserirne un\'altra'}
                />
                <Button variant={'submit-primary'}
                        endIcon={<Send/>}
                        disabled={(!emailCatering || !testEmail(emailCatering))}
                        sx={{ml: 'auto'}}
                        onClick={handleSend}
                >
                    Invia recap al catering
                </Button>
            </Paper>*/}

            <Typography fontWeight={'bold'}>Catering del tour</Typography>
            <Typography variant={'caption'}>Alle email indicate verrà inviato il report giornaliero nelle tappe di
                riferimento del catering</Typography>
            <CustomDivider/>
            <Stack mb={2} alignItems={'center'} direction={"row"} flexWrap={'wrap'}>
                {
                    tourCaterings?.map((tourCatering, i) => {
                        return <Card sx={{marginBottom: 1, marginRight: 1}}>
                            <CardContent component={Stack} spacing={1.2} alignItems={'start'}>
                                <TextField
                                    id={'name'}
                                    size={'small'}
                                    variant={'standard'}
                                    fullWidth
                                    required
                                    value={tourCatering?.name || ''}
                                    onChange={(event) => handleChangeCatering(event, i)}
                                    label={"Catering"}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><Restaurant color={'secondary'}
                                                                                                     fontSize={'small'}/></InputAdornment>,
                                    }}
                                />
                                <TextField
                                    id={'email'}
                                    size={'small'}
                                    variant={'standard'}
                                    fullWidth
                                    value={tourCatering?.email || ''}
                                    onChange={(event) => handleChangeCatering(event, i)}
                                    label={"E-mail catering"}
                                />
                            </CardContent>
                            <CardActions sx={{marginLeft: 'auto'}}>
                                <Button variant={'outlined'}
                                        size={'small'}
                                        color={"secondary"}
                                        endIcon={<Save/>}
                                        disabled={!tourCatering.name}
                                        onClick={() => {
                                            axios.put(TourService.tourCateringsDataUrl(tour_id, tourCatering?.id || null),
                                                {
                                                    email: tourCatering.email,
                                                    name: tourCatering.name
                                                })
                                                .then(r => {
                                                    console.log(r)
                                                    fetchTourCaterings()
                                                    fetchTourDates()
                                                    setMessage({
                                                        ...message,
                                                        show: true,
                                                        text: "Aggiornamento andato a buon fine",
                                                        severity: "success"
                                                    })
                                                })
                                        }}
                                >
                                    Salva
                                </Button>
                                {!tourCatering.id &&
                                    <Button size={'small'}
                                            color={"error"}
                                            endIcon={<Cancel/>}
                                            onClick={(event) => handleDelete(event, i)}
                                    >
                                        Elimina
                                    </Button>}
                            </CardActions>
                        </Card>
                    })
                }
                <CustomTooltip title={'Aggiungi Catering'} children={
                    <IconButton onClick={() => {
                        setTourCaterings([...tourCaterings, {email: '', name: ''}])
                    }}>
                        <AddCircle fontSize={'large'}/>
                    </IconButton>
                }/>
            </Stack>

            <Typography fontWeight={'bold'}>Date del tour</Typography>
            <CustomDivider/>
            <Stack direction={"row"} flexWrap={'wrap'}>
                {
                    tourDates?.map((tourDate, i) => {
                        const currentCatering = find(tourCaterings, ['id', tourDate?.catering_id])
                        return <Card sx={{marginBottom: 1, marginRight: 1, width: '32%',
                            borderWidth: dayjs().isBetween(dayjs(tourDate?.start_date), dayjs(tourDate?.end_date), 'day', "[]")
                                ? 2 : 1,
                            borderColor: dayjs().isBetween(dayjs(tourDate?.start_date), dayjs(tourDate?.end_date), 'day', "[]")
                            ? alpha(sticketTheme.palette.secondary.main, 0.6) : null
                        }}
                                     variant={dayjs().isAfter(dayjs(tourDate?.start_date))
                                         ? 'outlined' : 'elevation'}
                        >
                            <CardHeader
                                avatar={
                                    <Avatar sx={{
                                        bgcolor:
                                            dayjs().isAfter(dayjs(tourDate?.end_date))
                                                ? alpha(sticketTheme.palette.primary.main, 0.6)
                                                : dayjs().isBetween(dayjs(tourDate?.start_date), dayjs(tourDate?.end_date), 'day', "[]")
                                                    ? sticketTheme.palette.primary.main
                                                    : 'disabled'
                                    }}
                                            aria-label="recipe">
                                        <Tour/>
                                    </Avatar>
                                }
                                action={
                                    <AddTourDateDialog icon={<Edit fontSize={'small'} />} tourDate={tourDate} caterings={tourCaterings} refresh={fetchTourDates}/>
                                }
                                title={(tourDate?.name).toUpperCase()}
                                subheader={`${dayjs(tourDate?.start_date).format('LL')} - ${dayjs(tourDate?.end_date).format('LL')}`}
                            />
                            <CardContent component={Stack} spacing={1.2} alignItems={'start'}>
                                <Chip label={`Catering "${currentCatering?.name || '-'}"`}
                                      icon={<Restaurant fontSize={'small'}/>}
                                      deleteIcon={<CustomChangeTourDateCatering tourDate={tourDate}
                                                                                caterings={tourCaterings}
                                                                                selectedCatering={tourDate.catering_id}
                                                                                refresh={fetchTourDates}
                                      />}
                                      onDelete={() => {}}
                                />
                                <Chip label={`${currentCatering?.email || '-'}`}
                                      size={'small'}
                                      variant={'outlined'}
                                      icon={<AlternateEmail fontSize={'small'}/>}
                                />
                            </CardContent>
                        </Card>
                    })
                }
                <Card component={Stack} display={'flex'} justifyContent={'center'} alignItems={'center'}
                      mb={1} mr={1} width={'32%'} sx={{background: 'transparent'}} variant={'outlined'}>
                    <AddTourDateDialog icon={<AddCircle fontSize={'large'}/>} label={'Aggiungi Tappa'} caterings={tourCaterings} refresh={fetchTourDates}/>
                </Card>
            </Stack>
        </Stack>
    );
}

export default SticketSettings