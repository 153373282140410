import {API_URL} from "../config";
import queryString from "query-string";

const usersUrl = (tour_id) => {
    return `${API_URL}/tours/${tour_id}/users`
}

const userDataUrl = (tour_id, id) => {
    return `${usersUrl(tour_id)}/${id||''}`
}

const usersMealsDataUrl = (tour_id, params = {date: null, meal: null}) => {
    const query = queryString.stringify(params);
    return `${usersUrl(tour_id)}?${query}`
}

const archiveUserUrl = (id, archive) => {
    return archive ? `${userDataUrl(id)}/archive` : `${userDataUrl(id)}/unarchive`
}

const UserService = {
    usersUrl,
    userDataUrl,
    usersMealsDataUrl,
    archiveUserUrl,
};

export default UserService;
