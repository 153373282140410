import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import CustomBackdrop from "../../../components/CustomBackdrop";
import {useFormik} from "formik";
import _, {cloneDeep, isEmpty, omit, replace} from "lodash";
import useAxios from "axios-hooks";
import {fieldTypes, usersTypes} from "../../../services/utils";
import UserService from "../../../services/user.service";
import {
    Alert, Autocomplete,
    Box,
    Button,
    Checkbox,
    Chip,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    Snackbar,
    Stack,
    TextField
} from "@mui/material";
import {Save} from "@mui/icons-material";
import {sxTextField} from "../UserDetails";
import SectionTitle from "../../../components/SectionTitle";
import dayjs from "dayjs";
import * as Yup from "yup";
import DateRangesComponent from "./DateRangesComponent";
import CounterCustom from "../../../components/CustomCounter";
import TourService from "../../../services/tour.service";

const GuestSchemaValidation = Yup.object().shape({
    referent: Yup.boolean(),
    company: Yup.string().required("Campo obbligatorio"),
    meals_expire_at: Yup.date().required("Campo obbligatorio"),
    name: Yup.string().when("referent", {
        is: true,
        then: () => Yup.string().required("Campo obbligatorio")
    }),
    surname: Yup.string().when("referent", {
        is: true,
        then: () => Yup.string().required("Campo obbligatorio")
    }),
    has_meals: Yup.boolean(),
    local: Yup.boolean(),
});

const GuestForm = ({user, referent = false, refresh, handleClose, handleNextStep}) => {
    const {tour_id} = useParams()

    const editMode = !!user

    const [{data: companies, loading: loadingTourCompanies, error: errorTourCompanies}, fetchTourCompanies] = useAxios(
        TourService.tourDataUrl(tour_id)+"/companies", {method: "GET", useCache: false, manual: false}
    )

    const [{data: userDto, loading: loadingUpdate, error: errorUpdate}, updateUser] = useAxios({},
        {manual: true}
    )

    const [message, setMessage] = useState({show: false, text: "", severity: "success"})
    const [errorFormik, setError] = useState({})
    const [state, setState] = useState({numGuest: 1, numMeals: user?.num_meals || 1})

    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setMessage({...message, show: false});
    };

    const initialValues = {
        company: user?.company || '',
        referent: referent,
        name: user?.name || '',
        surname: user?.surname || '',
        has_meals: user?.has_meals || true,
        local: user?.local || false,
        meals_expire_at: editMode ? dayjs(user?.date_ranges[0]?.end_date) : dayjs(),
        date_ranges: editMode ? user?.date_ranges : null
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: GuestSchemaValidation,
        validateOnChange: true,
        validateOnBlur: false,
        validateOnMount: false, //lasciare a false
        onSubmit: (values) => {
            if (isEmpty(errorFormik))
                handleSubmitForm()
        }
    });

    useEffect(() => {
        formik.setFieldValue('referent', referent)
    }, [referent])

    const invalidateFormik = (fieldId, value) => {
        //console.log("fieldId:",fieldId)
        //console.log("value:",value)
        //console.log("errorFormik:", errorFormik)
        const err = cloneDeep(errorFormik)
        if (!!value)
            setError({...errorFormik, [fieldId]: value})
        else {
            const res = omit(err, fieldId)
            //console.log("res omit:",res)
            setError(res)
        }
    }

    const handleSubmitForm = () => {
        let dto = {}
        if(editMode) {
            Object.keys(formik.touched).forEach((touchedField) => {
                if (formik.values[touchedField] !== formik.initialValues[touchedField]) {
                    if (touchedField === 'note') {
                        dto[touchedField] = replace(replace(formik.values[touchedField].trim(), /\t|\n/g, " "), /\s{2,}/g, " ")
                    } else {
                        dto[touchedField] = formik.values[touchedField]
                    }
                }
            })
        } else {
            dto = formik.values
            dto['num_meals'] = state.numMeals
            dto['num_meals_available'] = state.numMeals
        }

        if (formik.values.referent) {
            dto['type'] = usersTypes.REFERENT.id
            dto['name'] = formik.values.name
            dto['surname'] = formik.values.surname
            dto['has_meals'] = formik.values.has_meals
        } else {
            dto = omit(dto, ['name', 'surname'])
        }

        dto = omit(dto, ['referent', 'meals_expire_at'])

        dto = {...dto, type: formik.values.referent ? 'referent' : 'guest'}

        //console.log("dto:", dto)

        if (!_.isEmpty(dto)) {
            updateUser({
                data: dto,
                url: editMode ? UserService.userDataUrl(tour_id, user?.id)
                    : UserService.usersUrl(tour_id) + '/' + (state.numGuest || 1)+`?is_ref=${formik.values.referent}`,
                method: editMode ? "PUT" : "POST"
            })
                .then(async (res) => {
                    handleNextStep(editMode ? [res.data] : res.data)
                    setMessage({
                        ...message,
                        show: true,
                        text: `${editMode ? "Aggiornamento effettuato" : "Utente aggiunto"} con successo`,
                        severity: "success"
                    })
                })
                .catch((err) => setMessage({
                    ...message,
                    show: true,
                    text: "Aggiornamento non andato a buon fine",
                    severity: "error"
                }))
        }
    }

    return (
        <Stack mt={3}>
            <CustomBackdrop open={loadingUpdate}/>
            <form onSubmit={formik.handleSubmit}>
                <Stack mt={2}>
                    <SectionTitle title={"Informazioni"}/>
                    <Grid container spacing={3}
                          alignItems={'flex-end'}
                          sx={sxTextField}
                    >
                        <Grid item xs={12} sm={6}>
                            <Autocomplete
                                id={'company'}
                                name={'company'}
                                required
                                freeSolo
                                loading={loadingTourCompanies}
                                options={(!errorTourCompanies && companies) ? companies : []}
                                disableClearable
                                value={formik.values.company}
                                onChange={(event, newValue) => {
                                    formik.setFieldValue('company', newValue)
                                    formik.setFieldTouched('company')
                                }}
                                //onChange={(!field.precompiled && !disabled) ? formik.handleChange : null}
                                onBlur={formik.handleBlur}
                                sx={{width: '100%'}}
                                renderInput={(params) =>
                                    <TextField {...params}
                                               variant={'standard'}
                                               size={'small'}
                                               required
                                               onChange={formik.handleChange}
                                               onBlur={formik.handleBlur}
                                               error={formik.touched.company && !!formik.errors.company}
                                               label={"Azienda"}/>}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} >
                            {!editMode && <CounterCustom id={'numGuest'} label={'Numero di ospiti'}
                                                         state={state} setState={setState}/>}
                        </Grid>
                        {!formik.values.referent && <Grid item xs={12} sm={6}>
                            <FormControl>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Checkbox
                                            id={'local'}
                                            name={'local'}
                                            checked={formik.values.local}
                                            //onChange={formik.handleChange}
                                            //onBlur={formik.handleBlur}
                                            onChange={(event, checked) => {
                                                formik.setFieldValue('local', checked)
                                                formik.setFieldTouched('local')
                                            }}
                                        />} label={'GUEST LOCAL'}/>
                                </FormGroup>
                            </FormControl>
                        </Grid>}
                        {formik.values.referent &&
                            <>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        variant={'standard'}
                                        size={'small'}
                                        id={'name'}
                                        name={'name'}
                                        required
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.name && !!formik.errors.name}
                                        label={'Nome referente'}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        variant={'standard'}
                                        size={'small'}
                                        id={'surname'}
                                        name={'surname'}
                                        required
                                        value={formik.values.surname}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.surname && !!formik.errors.surname}
                                        label={'Cognome referente'}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={<Checkbox
                                                    id={'has_meals'}
                                                    name={'has_meals'}
                                                    checked={formik.values.has_meals}
                                                    //onChange={formik.handleChange}
                                                    //onBlur={formik.handleBlur}
                                                    onChange={(event, checked) => {
                                                        formik.setFieldValue('has_meals', checked)
                                                        formik.setFieldTouched('has_meals')
                                                    }}
                                                />} label={'Il referente ha bisogno del pasto'}/>
                                        </FormGroup>
                                    </FormControl>
                                </Grid>
                            </>}
                    </Grid>
                    <Stack mt={2}>
                        <Stack direction={'row'} alignItems={'center'} spacing={1}>
                            <SectionTitle title={"PASS"}/>
                            <Chip label={state.numGuest} color={'secondary'} sx={{fontWeight: 'bold'}} size={'small'}/>
                        </Stack>
                        <Grid container spacing={3}
                              alignItems={'flex-end'}
                              sx={sxTextField}
                        >
                            {!editMode && <Grid mt={2} item xs={12} sm={6}>
                                <CounterCustom id={'numMeals'} label={'Numero di pasti a persona'}
                                                             state={state}
                                                             setState={setState}/>
                            </Grid>}
                            <Grid mt={2} item xs={12} sm={12}>
                                <DateRangesComponent referent formik={formik} invalidateFormik={invalidateFormik} field={
                                    {
                                        id: 'date_ranges',
                                        name: 'date_ranges',
                                        label: 'Validità temporale del buono pasto',
                                        type: 'date_ranges',
                                        required: true,
                                        cols: {
                                            xs: 12,
                                            md: 12
                                        }
                                    }
                                }/>
                                {/*<LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DesktopDatePicker
                                        format={'DD/MM/YYYY'}
                                        label={'Data ultimo pasto'}
                                        minDate={dayjs()}
                                        disablePast
                                        value={formik.values.meals_expire_at}
                                        onChange={(date, selectionState) => {
                                            //console.log("inizio date:", date)
                                            //console.log("inizio FINE:", dayjs(range.end_date))
                                            //console.log("inizio selectionState:", selectionState)
                                            if (date) {
                                                formik.setFieldValue('meals_expire_at', date)
                                                formik.setFieldTouched('meals_expire_at')
                                            }
                                            invalidateFormik('meals_expire_at', selectionState.validationError)

                                        }}
                                        sx={dateSx}
                                    />
                                </LocalizationProvider>*/}
                            </Grid>
                        </Grid>
                    </Stack>
                </Stack>
                <Box mt={4} display={'flex'} justifyContent={'center'}>
                    <Button
                        type={'submit'}
                        //disabled={!formik.isValid || !isEmpty(errorFormik)}
                        variant={'submit-accent'}
                        startIcon={<Save/>}
                    >
                        SALVA E {editMode ? "MODIFICA" : "GENERA"} {state.numGuest} PASS
                    </Button>
                </Box>
            </form>
            <Snackbar open={message.show} autoHideDuration={6000} onClose={handleCloseAlert}>
                <Alert elevation={6} variant={"filled"} onClose={handleCloseAlert}
                       severity={message.severity}
                       sx={{width: '100%'}}>
                    {message.text}
                </Alert>
            </Snackbar>
        </Stack>
    )

}

export default GuestForm