import * as React from "react";
import {useState} from "react";
import IconButton from "@mui/material/IconButton";
import {Close, Visibility} from "@mui/icons-material";
import {Box, Button, Dialog, DialogContent, DialogContentText, Stack} from "@mui/material";
import UserDetails from "./UserDetails";

const UserDetailsDialog = ({user, labelButton = null, icon = <Visibility/>, refresh}) => {
    const [open, setOpen] = useState(false);

    function handleOpenDialog(e, reason: "backdropClick" | "escapeKeyDown") {
        if(open)
            refresh()
        if(reason !== 'backdropClick')
            setOpen(!open)
    }

    return <Stack>
            {labelButton ?
                <Button startIcon={icon} variant={'submit-primary'}
                        onClick={(e) => {
                            e.stopPropagation()
                            handleOpenDialog()
                        }}
                >
                    {labelButton}
                </Button>
                : <IconButton onClick={(e) => {
                    e.stopPropagation()
                    handleOpenDialog()
                }}>
                    {icon}
                </IconButton>}
        <Dialog open={open} onClose={handleOpenDialog} fullWidth maxWidth={'sm'}>
            <DialogContent>
                <DialogContentText variant={'h5'} fontWeight={'bold'}>
                    <Box display={'flex'}>
                        Dettagli utente
                        <IconButton onClick={handleOpenDialog}
                                    children={<Close/>}
                                    sx={{ml: 'auto'}}
                        />
                    </Box>
                </DialogContentText>
                <UserDetails user={user} refresh={refresh} handleClose={handleOpenDialog}/>
            </DialogContent>
        </Dialog>
    </Stack>
}

export default UserDetailsDialog