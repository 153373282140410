import dayjs from "dayjs";
import React, {useEffect} from "react";
import {cloneDeep} from "lodash";
import {Avatar, Box, Button, ListItemAvatar, Stack, Typography} from "@mui/material";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {sticketTheme} from "../../../theme/sticketTheme";
import {DesktopDatePicker} from "@mui/x-date-pickers";
import CustomTooltip from "../../../components/CustomTooltip";
import IconButton from "@mui/material/IconButton";
import {Add, RemoveCircle} from "@mui/icons-material";

export const dateSx = {
    '.MuiInputBase-input': {
        padding: 1,
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderWidth: 0.5,
            borderColor: sticketTheme.palette.accent.main,
        },
        '&:hover fieldset': {
            borderColor: sticketTheme.palette.secondary.main,
        },
        '&.Mui-focused fieldset': {
            borderColor: sticketTheme.palette.secondary.main,
        },
    },
}

const DateRangesComponent = ({referent, formik, field, invalidateFormik}) => {
    const initialDates = [{
        start_date: dayjs().format('YYYY-MM-DD'),
        end_date: dayjs().add(1, 'day').format('YYYY-MM-DD')
    }]

    const handleAddDateRange = () => {
        const newRanges = formik.values[field.id] ? cloneDeep(formik.values[field.id]) : []
        newRanges.push({
            start_date: formik.values[field.id] ?
                dayjs(formik.values[field.id][formik.values[field.id]?.length - 1]?.end_date)
                    .add(1, 'day').format('YYYY-MM-DD')
                : dayjs().format('YYYY-MM-DD'),
            end_date: formik.values[field.id] ?
                dayjs(formik.values[field.id][formik.values[field.id].length - 1]?.end_date).add(2, 'day')
                    .add(1, 'day').format('YYYY-MM-DD')
                : dayjs().format('YYYY-MM-DD')
            //dayjs(formik.values[field.id][formik.values[field.id].length-1]?.end_date).add(2, 'day'),
        })

        formik.setFieldValue(field.id, newRanges)
        formik.setFieldTouched(field.id)
    }

    const handleDeleteRange = (index) => {
        if (index !== 0) {
            const newRanges = cloneDeep(formik.values[field.id])
            newRanges.splice(index, 1)

            formik.setFieldValue(field.id, newRanges)
            formik.setFieldTouched(field.id)
        }
    }

    useEffect(() => {
        if (!formik.values[field.id]) {
            formik.setFieldValue(field.id, initialDates)
        }
    }, [])

    return (<Stack alignItems={'flex-start'} spacing={1}>
            <Typography gutterBottom variant={'caption'} color={'secondary'}>{field.label || ''}</Typography>
            {formik.values[field.id] ?
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    {
                        (formik.values[field.id])?.map((range, index) => (
                            <Stack key={index} mt={1} alignItems={'center'} direction={'row'} spacing={1}>
                                {!referent && <ListItemAvatar>
                                    <Avatar sx={{
                                        width: 24, height: 24,
                                        background: sticketTheme.palette.secondary.main,
                                        color: sticketTheme.palette.primary.main,
                                        fontWeight: 'bold'
                                    }}>
                                        {(index + 1).toString()}
                                    </Avatar>
                                </ListItemAvatar>}
                                <DesktopDatePicker
                                    format={'DD/MM/YYYY'}
                                    label={!range.start_date ? 'Invalid Date' : 'Data inizio'}
                                    /*minDate={index > 0 ? dayjs(formik.values[field.id][index - 1]?.end_date)
                                        .add(1, 'day') : undefined}*/
                                    //maxDate={dayjs(range?.end_date)}
                                    value={dayjs(range.start_date
                                        || formik.values[field.id][index - 1]?.end_date)}
                                    onChange={(date, selectionState) => {
                                        //console.log("inizio date:", date)
                                        //console.log("inizio FINE:", dayjs(range.end_date))
                                        //console.log("inizio selectionState:", selectionState)
                                        if (date) {
                                            if (date.isAfter(dayjs(range.end_date))) {
                                                invalidateFormik(field.id + '_start_date', 'minDateErr')
                                            } else {
                                                invalidateFormik(field.id + '_start_date', null)
                                            }

                                            const res = cloneDeep(formik.values[field.id])
                                            res[index] = {
                                                ...res[index],
                                                start_date: dayjs(date).format('YYYY-MM-DD')
                                            }

                                            formik.setFieldValue(field.id, res)
                                            formik.setFieldTouched(field.id)
                                        }

                                        invalidateFormik(field.id + '_start_date_val', selectionState.validationError)
                                    }}
                                    sx={dateSx}
                                />
                                <Typography>-</Typography>
                                <DesktopDatePicker
                                    format={'DD/MM/YYYY'}
                                    label={'Data fine'}
                                    minDate={dayjs(range.start_date)}
                                    /*maxDate={index < formik.values[field.id]?.length - 1
                                        ? dayjs(formik.values[field.id][index + 1]?.start_date)
                                            .subtract(1, 'day') : undefined}*/
                                    value={dayjs(range.end_date || range.start_date)}
                                    onChange={(date, selectionState) => {
                                        //console.log("FINE date:", date)
                                        //console.log("FINE selectionState:", selectionState)
                                        if (date) {
                                            if (date.isBefore(dayjs(range.start_date))) {
                                                invalidateFormik(field.id + '_end_date', 'minDateErr')
                                            } else {
                                                invalidateFormik(field.id + '_end_date', null)
                                            }

                                            const res = cloneDeep(formik.values[field.id])
                                            res[index] = {
                                                ...res[index],
                                                end_date: dayjs(date).format('YYYY-MM-DD')
                                            }

                                            formik.setFieldValue(field.id, res)
                                            formik.setFieldTouched(field.id)
                                        }

                                        invalidateFormik(field.id + '_end_date_val', selectionState.validationError)
                                    }}
                                    sx={dateSx}
                                />
                                {
                                    <Box visibility={index === 0 ? 'hidden' : 'inherit'}>
                                        <CustomTooltip title={'Elimina'} children={
                                            <IconButton size={'small'}
                                                        color={'secondary'}
                                                        disabled={index === 0}
                                                        onClick={() => handleDeleteRange(index)}
                                                        children={
                                                            <RemoveCircle fontSize={'small'}/>
                                                        }/>
                                        }/>
                                    </Box>
                                }
                            </Stack>
                        ))
                    }
                </LocalizationProvider>
                : null
            }
            {!referent && <Button
                onClick={handleAddDateRange}
                variant={'outlined'}
                color={'secondary'}
                size={'small'}
                startIcon={<Add/>}
                sx={{borderRadius: '2rem'}}
            >
                Aggiungi
            </Button>}
        </Stack>
    );
}

export default DateRangesComponent