import React, {useState} from "react";
import {Alert, Box, Button, Card, Chip, Divider, Snackbar, Stack, TextField, Typography} from "@mui/material";
import {ArrowBack, ArrowBackIos, ArrowLeft, NoMeals, Save, Verified} from "@mui/icons-material";
import SectionTitle from "../../../components/SectionTitle";
import {useParams} from "react-router-dom";
import useAxios from "axios-hooks";
import {cloneDeep, find} from "lodash";
import IconButton from "@mui/material/IconButton";
import {sticketTheme} from "../../../theme/sticketTheme";
import CustomBackdrop from "../../../components/CustomBackdrop";
import UserService from "../../../services/user.service";
import CustomShareButton from "../../../components/CustomShareButton";
import CounterCustom from "../../../components/CustomCounter";
import dayjs from "dayjs";
import {usersTypes} from "../../../services/utils";
import {QrCodeToPrintHandler} from "../../../components/print/FunctionalComponent";

const PassesForm = ({editMode, users, refresh, handleBack}) => {
    const {tour_id} = useParams()

    const [{data: userDto, loading: loadingUpdate, error: errorUpdate}, updateUser] = useAxios({},
        {manual: true}
    )

    const [state, setState] = useState(users)
    const [message, setMessage] = useState({show: false, text: "", severity: "success"})

    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setMessage({...message, show: false});
    };

    function handleChangeState(e, i) {
        const usrs = cloneDeep(state)

        usrs[i] = {
            ...state[i],
            [e.target.id || e.target.name]: e.target.value
        }

        setState(usrs)
    }

    function handleSavePass(e, i) {
        updateUser({
            data: state[i],
            url: UserService.userDataUrl(tour_id, state[i]?.id),
            method: "PUT"
        })
            .then((res) => {
                setMessage({
                    ...message,
                    show: true,
                    text: `${editMode ? "Pass aggiornato" : "Pass assegnato"} con successo`,
                    severity: "success"
                })
                //refresh()
            })
            .catch((err) => {
                setMessage({
                    ...message,
                    show: true,
                    text: "Aggiornamento non andato a buon fine",
                    severity: "error"
                })
            })
    }

    async function handleSaveAllPasses() {
        for (let i = 0; i < state.length; i++) {
            await updateUser({
                data: state[i],
                url: UserService.userDataUrl(tour_id, state[i]?.id),
                method: "PUT"
            })
                .catch((err) => {
                    setMessage({
                        ...message,
                        show: true,
                        text: "Aggiornamento non andato a buon fine",
                        severity: "error"
                    })
                })
        }
    }

    return (
        <Stack>
            <Button startIcon={<ArrowBack/>} onClick={handleBack}
                    color={'secondary'}
                    sx={{marginRight: 'auto', mb: 2}}>
                 Torna indietro
            </Button>
            <CustomBackdrop open={loadingUpdate}/>
            {/*<Typography gutterBottom variant={'subtitle2'}>Assegna Pass</Typography>*/}
            {
                state.map((user, i) => (
                    <Card key={i} sx={{marginBottom: 1, alignItems: 'center'}}>
                        {/*<CardMedia
                                sx={{height: 70}}
                                image={header}
                                // TODO: fare un header caruccio
                                title="green iguana"
                            />*/}
                        <Stack px={1.5} py={1.8} direction={'row'} alignItems={'center'} spacing={2}>
                            <Stack width={'100%'}>
                                {user.type === usersTypes.REFERENT.id &&
                                    <Chip label={`REFERENTE: ${user.name} ${user.surname}`}
                                          icon={<Verified/>}
                                          color={'secondary'}
                                          sx={{marginBottom: 1}}
                                    />}
                                {user.has_meals && <CounterCustom id={'num_meals'} label={'Numero di pasti a persona'} variant={'light'}
                                                state={state[i]}
                                                setState={(newVal) => {
                                                    const usrs = cloneDeep(state)
                                                    const diff = newVal.num_meals - usrs[i].num_meals
                                                    const newAvailable = state[i].num_meals_available + diff

                                                    usrs[i] = {
                                                        ...newVal,
                                                        num_meals_available: Math.max(newAvailable, 0)
                                                    }

                                                    setState(usrs)
                                                }}/>}
                                <TextField
                                    sx={{marginTop: 2}}
                                    fullWidth
                                    variant={'outlined'}
                                    size={'small'}
                                    id={'notes'}
                                    name={'notes'}
                                    value={state[i].notes}
                                    onChange={(e) => handleChangeState(e, i)}
                                    label={'Note'}
                                    /** TODO: DA VERIFICARE */
                                    helperText={'Suggerimento: Scrivi a chi lo stai assegnando.'}
                                />
                            </Stack>
                            <Divider variant={'fullWidth'} orientation={'vertical'} flexItem
                                     sx={{borderStyle: 'dashed'}}/>
                            <Box sx={{
                                padding: 0.5,
                                maxWidth: '132px',
                                border: `3px solid ${sticketTheme.palette.secondary.main}`,
                                borderRadius: '1rem',
                                borderStyle: 'dashed'
                            }} display={'flex'} alignItems={'center'}>
                                {user.qr_code_url ?
                                    <img src={user.qr_code_url} width={'100px'}/>
                                    : <Chip icon={<NoMeals/>} label={!user.has_meals ? 'NO PASTI' : 'NO QR CODE'}/>
                                }
                            </Box>
                        </Stack>
                        <Divider variant={'fullWidth'} sx={{borderStyle: 'dashed'}}/>
                        <Stack pl={1.5} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                            <Stack py={1} spacing={-0.5}>
                                <Typography variant={'caption'}>Valido fino al: {dayjs(user.date_ranges[0]?.end_date).format('DD/MM/YY')}</Typography>
                                <Typography variant={'caption'} color={'grey'}>ID: {user.id}</Typography>
                            </Stack>
                            <Stack pb={0.5} px={1} direction={'row'} justifyContent={'flex-end'}>
                                <IconButton onClick={(e) => handleSavePass(e, i)}>
                                    <Save/>
                                </IconButton>
                                <Box onClick={(e) => handleSavePass(e, i)}>
                                    <QrCodeToPrintHandler
                                        multiple={true}
                                        buttonSize={'medium'}
                                        qrCodes={[
                                            {
                                                name: (!!user.name && !!user.surname)
                                                    ? `${user.name} ${user.surname}` : ``,
                                                info: `${user.type === 'employee' ? '' : 'GUEST'} - ${user.company}`,
                                                qrCode: user.qr_code_url, mealInfo: `${user.num_meals||''}`
                                            }
                                        ]}
                                    />
                                </Box>
                                <Box onClick={(e) => handleSavePass(e, i)}>
                                    <CustomShareButton user={user} refresh={refresh} disablePortal/>
                                </Box>
                            </Stack>
                        </Stack>
                        <Snackbar open={message.show} autoHideDuration={6000} onClose={handleCloseAlert}>
                            <Alert elevation={6} variant={"filled"} onClose={handleCloseAlert}
                                   severity={message.severity}
                                   sx={{width: '100%'}}>
                                {message.text}
                            </Alert>
                        </Snackbar>
                    </Card>
                ))
            }
            <Box mt={4} display={'flex'} justifyContent={'center'}>
                <Button
                    onClick={handleSaveAllPasses}
                    //disabled={}
                    variant={'submit-accent'}
                    startIcon={<Save/>}
                >
                    {editMode ? "MODIFICA" : "SALVA TUTTI I"} PASS
                </Button>
            </Box>
        </Stack>
    );
}

export default PassesForm