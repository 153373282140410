import React, {useMemo, useRef, useState} from "react";
import {Badge, Box, Chip, LinearProgress, Stack} from "@mui/material";
import CustomTooltip from "../../components/CustomTooltip";
import UserDetailsDialog from "./UserDetailsDialog";
import ColumnsService from "../../services/columns.service";
import {Add, Edit, FilterNone, LibraryAddCheck} from "@mui/icons-material";
import {DataGrid, itIT} from "@mui/x-data-grid";
import CustomGridToolbar from "../../components/data-grid/CustomGridToolbar";
import {useParams} from "react-router-dom";
import {includes} from "lodash";
import {QrCodeToPrintHandler} from "../../components/print/FunctionalComponent";
import {sticketTheme} from "../../theme/sticketTheme";

const MealsDatagrid = ({selectedMealType, mealsData, loadingMealsData, fetchMealsData}) => {
    const {tour_id} = useParams()

    const [pageSize, setPageSize] = useState(10)

    const columns = useMemo(() => {
        function handleOpenUserDetails(event, userId) {
            window.open(
                `users/${userId}`,
                "_blank",
                "noreferrer"
            )
            event.stopPropagation()
        }

        const actionCols = [
            {
                field: "actions",
                headerName: "Actions",
                align: "center",
                width: 50,
                sortable: false,
                renderHeader: () => <></>,
                renderCell: (params) => {
                    //console.log("params.row:",params.row)
                    return <Stack direction={'row'} alignItems={'center'}>
                        <CustomTooltip title={'Vedi utente'} children={
                            <UserDetailsDialog user={params.row} refresh={fetchMealsData} icon={<Edit/>}/>
                        }/>
                        {/*<CustomTooltip title={'Vedi utente in una nuova tab'} children={
                            <IconButton onClick={(event) => handleOpenUserDetails(event, params.row.id)} children={
                                <OpenInNew/>
                            }/>
                        }/>*/}
                    </Stack>
                }
            }
        ]

        //console.log("cols:", cols)
        return actionCols.concat(ColumnsService.getColumns({tour_id: tour_id, refresh: fetchMealsData}))
    }, [])

    const ref = useRef()

    const [checkboxSelection, setCheckboxSelection] = useState(false);
    const [rowSelectionModel, setRowSelectionModel] = useState([]);

    return (
        <Stack mt={2} spacing={1}>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} alignContent={'flex-end'}>
                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    <Badge badgeContent={rowSelectionModel?.length} color={'error'}>
                        <CustomTooltip title={`${checkboxSelection ? 'Disattiva' : 'Attiva'} selezione multipla`}
                                       children={
                                           <Chip label={'Selezione multipla'}
                                                 color={'primary'}
                                                 sx={{px:1, py:2}}
                                                 size={'small'}
                                                 variant={checkboxSelection ? "filled" : 'outlined'}
                                                 icon={checkboxSelection ? <LibraryAddCheck/> : <FilterNone/>}
                                                 onClick={() => setCheckboxSelection(!checkboxSelection)}
                                           />
                                       }/>
                    </Badge>
                    {rowSelectionModel.length > 0 &&
                        <Box borderRadius={'2rem'}
                             sx={{background: sticketTheme.palette.secondary.main}}
                        >
                            <QrCodeToPrintHandler
                                multiple={true}
                                color={'primary'}
                                qrCodes={rowSelectionModel}
                            />
                        </Box>}
                </Stack>

                {/*<Typography fontWeight={'bold'}>Utenti aventi diritto ai pasti nella giornata/pasto selezionati</Typography>*/}
                <Box ml={'auto'}>
                    <UserDetailsDialog user={null} labelButton={'Aggiungi Utente'} icon={<Add/>} refresh={fetchMealsData}/>
                </Box>
            </Stack>
            <DataGrid
                ref={ref}
                autoHeight
                localeText={{
                    ...itIT.components.MuiDataGrid.defaultProps.localeText,
                    toolbarExport: "Esporta colonne visibili",
                    noResultsOverlayLabel: "Nessun record trovato, prova la pagina dedicata alla gestione UTENTI"
                }}
                loading={loadingMealsData}
                //columnVisibilityModel={columnVisibilityModel}
                /*onColumnVisibilityModelChange={(newModel) =>
                    handleSaveConfiguration(newModel)
                }*/
                checkboxSelection={checkboxSelection}
                onSelectionModelChange={(newRowSelectionModel) => {
                    setRowSelectionModel(mealsData.filter((o) => {return includes(newRowSelectionModel, o.id)})
                        .map((user) =>({
                            name: (!!user.name && !!user.surname)
                                ? `${user.name} ${user.surname}` : ``,
                            info: ` - ${user.company}`,
                            qrCode: user.qr_code_url, mealInfo: `${user.num_meals||''}`
                        })));
                }}
                disableSelectionOnClick
                /*onRowClick={(params, event, details) => {
                    event.stopPropagation()
                    //navigate(`users/${params.row.id}`, {state: {activeFormId: 'activeForm?.id'}});
                    // TODO: Show row details
                }}*/
                components={{
                    Toolbar: () => {
                        return <CustomGridToolbar selectedMealType={selectedMealType}/>
                    },
                    LoadingOverlay: LinearProgress,
                }}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[10, 20, 50]}
                columns={!loadingMealsData ? (columns || []) : []}
                rows={mealsData || []}
                //checkboxSelection={true}
            />
        </Stack>
    );
}

export default MealsDatagrid