import {Button, Stack} from "@mui/material";
import MealsSummary from "../dashboard_view/MealsSummary";
import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {ArrowBack} from "@mui/icons-material";
import CustomTimelineMeals from "./nivo_graphs/CustomTimelineMeals";
import CustomBarMeals from "./nivo_graphs/CustomBarMeals";

const Reports = ({catering = false}) => {
    const {tour_id} = useParams()
    const navigate = useNavigate()

    function handleBack() {
        navigate(`/${tour_id}`)
    }

    return (<Stack m={1}>
        {catering && <Button startIcon={<ArrowBack/>} onClick={handleBack}
                 variant={'outlined'}
                 sx={{marginRight: 'auto', mb: 2}}>
            Torna indietro
        </Button>}
        <MealsSummary viewMode={'graph'} catering={catering}/>
        {/*!catering && <CustomTimelineMeals/>*/}
        {<CustomBarMeals catering={catering}/>}
    </Stack>);
}

export default Reports