import {useParams} from "react-router-dom";
import useAxios from "axios-hooks";
import MealService from "../../../services/meal.service";
import React, {useMemo} from "react";
import {each, reverse} from "lodash";
import dayjs from "dayjs";
import {Badge, Chip, Stack, Typography} from "@mui/material";
import SectionTitle from "../../../components/SectionTitle";
import {ResponsiveBar} from "@nivo/bar";
import relativeTime from "dayjs/plugin/relativeTime";
import {mealsTypes, useTourStore} from "../../../stores/TourStore";
import {Restaurant, Tour} from "@mui/icons-material";
import TourService from "../../../services/tour.service";

const CustomBarMeals = ({catering}) => {
    dayjs.locale("it");
    dayjs.extend(relativeTime);

    const {tour_id} = useParams()
    const {current_date, start_date, end_date} = useTourStore((state) => state)

    console.log("current_date:",current_date)

    /** TODO: OTTIMIZZARE API PER AVERE MEALS SOLO IN UN DETERMINATO PERIODO */
    const [{data: tourDatesStats, loading: loadingStats, error: errorStats}, fetchStats] = useAxios(
        `${TourService.tourDataUrl(tour_id)}/stats`, {method: "GET", useCache: false, manual: false}
    )

    const [{data: mealsData, loading: loadingMealsData, error: errorMealsData}, fetchMealsData] = useAxios(
        `${MealService.mealsUrl(tour_id)}`, {method: "GET", useCache: false, manual: false}
    )

    const cateringRange = (mealDateTime) => {
        return dayjs(mealDateTime).isBetween(
            dayjs(current_date?.start_date),
            dayjs(current_date?.end_date),
            'day',
            '[]'
        )
    }

    const parsedMeals = useMemo(() => {
        if (!mealsData)
            return null
        let res
        const accumulator = {}

        each(mealsData, (meal) => {
            if (meal && meal.datetime && (catering ?
                cateringRange(meal.datetime)
                : dayjs(meal.datetime).isAfter(dayjs(start_date)))) {
                const dateKey = dayjs(meal.datetime).format('ddd D MMM')//.format('DD/MM')
                //console.log("date:",date)
                //const _dateKey = `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`

                if (!accumulator[dateKey]) {
                    accumulator[dateKey] =
                        meal.type === mealsTypes.LUNCH.type ?
                            {counter: 1, lunch: 1, dinner: 0}
                            : {counter: 1, lunch: 0, dinner: 1}


                } else {
                    accumulator[dateKey] =
                        meal.type === mealsTypes.LUNCH.type ?
                            {
                                ...accumulator[dateKey],
                                counter: ++accumulator[dateKey].counter,
                                lunch: ++accumulator[dateKey].lunch
                            }
                            : {...accumulator[dateKey], counter: 1, lunch: 0, dinner: ++accumulator[dateKey].dinner}
                    accumulator[dateKey] = {...accumulator[dateKey], counter: ++accumulator[dateKey].counter}
                }
            }
        })
        //console.log("accumulator:",accumulator)

        res = Object.entries(accumulator).map(([key, counter], i) => {
            return {
                Data: key,
                Pranzi: counter.lunch,
                Cene: counter.dinner,
            }
        })

        res = res.sort((a, b) => {
            return dayjs(a.Data) > dayjs(b.Data) ? 1 : -1;
        })
        return catering ? reverse(res) : res
    }, [mealsData, catering, current_date, start_date])

    return (
        <Stack mt={3} height={'400px'}>
            <SectionTitle title={"Andamento pasti serviti tour"} color={'primary'}/>
            {
                !catering &&
                <Stack direction={"row"} alignItems={'center'} flexWrap={'wrap'} spacing={1}>
                    {
                        Object.values(tourDatesStats||{})?.map((date) => (
                            <Chip key={date.id}
                                  size={'small'}
                                  label={`${date.name}: ${date.mealCount}`}
                                  icon={<Tour/>}
                                  //color={'secondary'}
                                  deleteIcon={<Restaurant/>}
                                  onDelete={() => {}}
                            />
                        ))
                    }
                </Stack>
            }
            {parsedMeals ? <ResponsiveBar
                data={parsedMeals}
                keys={[
                    'Pranzi',
                    'Cene'
                ]}
                indexBy="Data"
                groupMode="stacked"

                layout={catering ? 'horizontal' : 'vertical'}
                margin={{top: 50, right: 130, bottom: 50, left: 60}}
                padding={0.3}
                valueScale={{type: 'linear'}}
                indexScale={{type: 'band', round: true}}
                //colors={{scheme: 'nivo'}}
                colors={['gold', 'lightblue']}
                borderColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            1.6
                        ]
                    ]
                }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 40,
                    legend: 'Date',
                    legendPosition: 'middle',
                    legendOffset: 42
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Pasti serviti',
                    legendPosition: 'middle',
                    legendOffset: -40
                }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            1.6
                        ]
                    ]
                }}
                legends={[
                    {
                        dataFrom: 'keys',
                        anchor: 'top-right',
                        direction: 'column',
                        justify: false,
                        translateX: 120,
                        translateY: 0,
                        itemsSpacing: 2,
                        itemWidth: 100,
                        itemHeight: 20,
                        itemDirection: 'left-to-right',
                        itemOpacity: 0.85,
                        symbolSize: 20,
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemOpacity: 1
                                }
                            }
                        ]
                    }
                ]}
                role="application"
            /> : <Typography textAlign={'center'}>Dati insufficienti</Typography>
            }
        </Stack>
    );
}

const CustomSymbol = ({size, color, borderWidth, borderColor}) => (
    <g>
        <circle fill="#fff" r={size / 2} strokeWidth={borderWidth} stroke={borderColor}/>
        <circle
            r={size / 5}
            strokeWidth={borderWidth}
            stroke={borderColor}
            fill={color}
            fillOpacity={0.35}
        />
    </g>
)

export default CustomBarMeals